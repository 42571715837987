import { Link } from "gatsby"
import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const seo = {
  metaTitle: 'Pagina niet gevonden',
}

const NotFoundPage = () => (
  <Layout page="404">
    <Seo seo={seo} />
    <div className="container" style={{ paddingTop: 60, paddingBottom: 60, textAlign: "center" }}>
      <div className="row">
        <div className="custom-col-12">
          <h2>404</h2>
          <p>Deze pagina bestaat niet meer.</p>
          <p><Link to="/">Terug naar de homepage</Link></p>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
